import Vue from "vue";
import moment from 'moment'

export default {
    version: "1.1",
    BASE_URL: 'https://mafra.brk-dev.ro/api/index.php',

    set_token: function(newToken) {
        window.localStorage.setItem("mafra-token", newToken);
        Vue.http.headers.common["Token"] = newToken;
    },

    get_token: function() {
        return window.localStorage.getItem("mafra-token");
    },

    set_drepturi: function (drepturi) {
        window.localStorage.setItem('mafra-drepturi_user', JSON.stringify(drepturi));
    },

    get_drepturi: function () {
        var drepturi = window.localStorage.getItem('mafra-drepturi_user');
        return JSON.parse(drepturi);
    },
    set_user_type: function (user_type) {
        window.localStorage.setItem('mafra-user_type', user_type);
    },

    get_user_type: function () {
        return window.localStorage.getItem('mafra-user_type');
    },

    is_logged_in: function() {
        var token = this.get_token();
        return token !== "";
    },

    logout: function() {
        this.set_token('');
        this.set_drepturi('');
    },

    verify_response: function(response) {
        if (response.body.NotLogged) {
            return false;
        }
        return true;
    },

    verify_login_and_redirect: function(vueInstance) {
        if (!this.is_logged_in()) {
            vueInstance.$router.push("/");
        }
    },
    datePickerOptions:{
        firstDayOfWeek: 1,
        shortcuts: [
            {
            text: 'Astazi',
            onClick: function (picker) {
                var from = moment(new Date()).format('YYYY-MM-DD 00:00:00')
                var to = moment(new Date()).format('YYYY-MM-DD 23:59:59')
                picker.$emit('pick', [from, to])
            }
            },
            {
            text: 'Ieri',
            onClick: function (picker) {
                var from = moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD 00:00:00')
                var to = moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD 23:59:59')
                picker.$emit('pick', [from, to])
            }
            },
            {
            text: 'Ultima sapt.',
            onClick: function (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [date, new Date()])
            }
            },
            {
            text: 'Luna curenta',
            onClick: function (picker) {
                var date = new Date()
                var y = date.getFullYear()
                var m = date.getMonth()
                var firstDay = new Date(y, m, 1)
                var lastDay = new Date(y, m + 1, 0, 23, 59, 59)
                firstDay = moment(firstDay).format('YYYY-MM-DD HH:mm:ss')
                lastDay = moment(lastDay).format('YYYY-MM-DD HH:mm:ss')
                console.log(firstDay, lastDay)
                // picker.$emit('pick', [firstDay, lastDay])
                picker.$emit('pick', ['2022-12-01 00:00:00' , '2022-12-31 23:59:59'])
            }
            },
            {
            text: 'Luna trecuta',
            onClick: function (picker) {
                var dateFrom = moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss')
                var dateTo = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss')
                picker.$emit('pick', [dateFrom, dateTo])
            }
            },
            {
            text: 'Anul curent',
            onClick: function (picker) {
                var thisYear = (new Date()).getFullYear()
                var start = new Date('1/1/' + thisYear)
                picker.$emit('pick', [start, new Date()])
            }
            },
            {
            text: 'Anul trecut',
            onClick: function (picker) {
                const date = new Date()
                const year = date.getFullYear()
                var lastYearStart = new Date(year - 1, 0, 1, '00', '00', '00')
                var lastYearEnd = new Date(year - 1, 11, 31, '23', '59', '59')
                picker.$emit('pick', [lastYearStart, lastYearEnd])
            }
            },
            {
            text: 'Tot timpul',
            onClick: function (picker) {
                let to = new Date()
                to.setHours(23, 59, 0)
                var from = new Date(2000, 0, 1, '00', '00', '00')

                from = moment(from).format('YYYY-MM-DD 00:00:00')
                to = moment(to).format('YYYY-MM-DD 23:59:59')

                picker.$emit('pick', [from, to])
            }
            }
        ]
    },
};
