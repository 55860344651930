import Vue from 'vue'
import App from './App.vue'
import router from './router'
import settings from "./backend/LocalSettings";
import moment from "moment";
import VueResource from "vue-resource";
import locale from "element-ui/lib/locale/lang/ro"
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BootstrapVue from 'bootstrap-vue'
import {BootstrapVueIcons} from 'bootstrap-vue';
import numeral from 'numeral';


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueResource);
Vue.use(ElementUI, { locale });

Vue.http.options.xhr = { withCredentials: true };
Vue.http.options.emulateJSON = true;
Vue.http.headers.common["Token"] = settings.get_token();

Vue.http.options.root = settings.BASE_URL;

Vue.filter( 'momentformat', function(value){
    return value;
} );

Vue.filter( 'LunaLitere', function(value){

  value=Number(value)
  if(value == 1) return 'Ianuarie';
  if(value == 2) return 'Februarie';
  if(value == 3) return 'Martie';
  if(value == 4) return 'Aprilie';
  if(value == 5) return 'Mai';
  if(value == 6) return 'Iunie';
  if(value == 7) return 'Iulie';
  if(value == 8) return 'August';
  if(value == 9) return 'Septembrie';
  if(value == 10) return 'Octombrie';
  if(value == 11) return 'Noiembrie';
  if(value == 12) return 'Decembrie';

} );

Vue.filter( 'DataOra', function(value){
  if(!value) return "-";
  return moment(value).format("DD.MM.YYYY HH:mm:ss");
} );

Vue.filter( 'DataOraFaraSec', function(value){
  if(!value) return "-";
  return moment(value).format("DD.MM.YYYY HH:mm");
} );

Vue.filter( 'Data', function(value){
  if(!value) return "-";
  return moment(value).format("DD.MM.YYYY");
} );

Vue.filter('format_money', function (value, format='0,0.00') {
    if (value !== undefined) {
      var nr = parseFloat(value)
      return numeral(nr).format(format)
    } else {
      return null
    }
  })

Vue.prototype.$has_right = function (rightKey)
{
    var ret             = false;
    var drepturiDecoded = settings.get_drepturi();
    var numarUnic       = (drepturiDecoded.length - 1) * (458 + 73 - 23)
    var caractereUnice  = 'nimic' + numarUnic
    var verificareCrc   = crypto.createHash('md5').update(Buffer.from(caractereUnice)).digest('hex')
    if (drepturiDecoded.indexOf(verificareCrc) !== -1)
    {
        var indexDrept = drepturiDecoded.indexOf(rightKey)
        if (indexDrept !== -1) ret = true;
    }
    return ret;
}


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
