<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import settings from "@/backend/LocalSettings";

export default {
  name: "App",
  methods: {
    async check_version() {
      var local_version = settings.version;
      var server_version = await this.post("version");
      if (local_version != server_version) {
        this.$notify({
          title: "Atentie",
          message:
            'A aparut o noua versiune - <button onclick="window.location.reload()">Upgrade</button> ',
          dangerouslyUseHTMLString: true,
          type: "warning",
          duration: 0,
        });
      }
    },
    post: async function (url, args = {}) {
      var response = await this.$http.post(url, args);
      return response.bodyText;
    },
  },
  mounted() {
    //verificam daca exista o versiune mai noua
    this.check_version();
  },
};
</script>

<style lang='less'>
@albastru-deschis: hsl(0, 57%, 53%);
@gri_inchis: #222831;
@gri_deschis: #393E46;
@portocaliu: #D65A31;
@alb: #EEEEEE;
@alb-inchis: #626a79;

.navbar-dark .navbar-toggler-icon {
  color: hsl(193, 11%, 34%) !important;
  fill: hsl(193, 11%, 34%) !important;
}

.el-table tr th .cell {
  // width: auto;
  word-break: normal;
  // font-size: 12px !important;
}

.el-table {
  border-radius: 5px;
}

.number {
  border-radius: 5px;
  color: hsl(193, 11%, 34%) !important;
}

.text-albastru {
  color: @albastru-deschis;
}

.bg-albastru {
  background-color: @albastru-deschis;
}

.display-titlu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
div.titlu {
  // background-color: hsl(196, 85%, 35%);
  h1 {
    color: @alb !important;
    // padding: 8px 15px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 25px;
  }
  .btn-adauga {
    // display: block;
    z-index: 1;
    text-align: center;
    height: 50px;
    // padding-left: 50px;
    // padding-right: 50px;
    // background: #409eff;
    color: @alb;
    background: transparent; /* Old browsers */
    border-radius: 10px !important;
    // background: -moz-linear-gradient(top, hsl(193, 11%, 34%) 0%, hsl(196, 11%, 57%) 50%, hsl(200, 11%, 58%) 100%); /* FF3.6-15 */
    // background: -webkit-linear-gradient(top, hsl(193, 11%, 34%) 0%,hsl(196, 11%, 57%) 50%,hsl(200, 11%, 58%) 100%); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(to top, hsl(193, 11%, 34%) 0%,hsl(196, 11%, 57%) 50%,hsl(200, 11%, 58%) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34c2e4', endColorstr='#4fabda',GradientType=0 ); /* IE6-9 */
    border: none;
    border-radius: 0%;
    margin: 2px;
  }

  .btn-adauga:hover {
    // color: white;
    color: @alb;

    // background-color: hsl(0, 0%, 71%);
    background-color: transparent;
  }
}

.el-card{
  background-color: @gri_deschis !important;
  color: @alb !important;
}

.el-form-item__label {
  color: @alb !important;
}

.filtre{
  background-color: @gri_deschis !important;
}

html { 
  height: 100%;
  background-position: center;
  background-size: cover;
  background-position: fixed;
  background-color: @gri_inchis;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: @gri_inchis !important;
}
.my-dialog-class .el-dialog {
  width: 80%;
}
.full-width {
  width: 100%;
}

thead {
  background-color: black;
}

.el-collapse-item__content {
  background-color: @gri_deschis;
  border: none !important;
}

.el-collapse-item__wrap {
  border: none !important;
}

.el-collapse-item__header {
  background-color: transparent !important;
  border: none !important;
  color: @alb !important;

}


@media only screen and (max-width: 768px) {
  .el-table-column {
    padding: 0 10px !important;
  }
}

 .Intrare{
    background-color: rgb(218, 246, 218);
    // color: white;
  }
  .Iesire{
    background-color: rgb(244, 180, 195);
    // color: white;
  }

  .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 100% !important;
}

    .el-form-item__content {
      width: 100% !important;
    }

  .el-zoom-in-top-enter-active, .el-zoom-in-top-enter-to {
    display: none !important;
  }

.my-popover {


  position: absolute !important;
  margin: auto !important;
  // border: 1px solid red !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  .popper__arrow::after {
    display: none !important;
  }


}

.el-switch__label{
  color: @alb !important;
}

.el-form--label-top .el-form-item__label {
  padding: 0 0 0px !important;
}

.butonReg {
  font-size: 9px !important;
  margin-right: 2px !important;
  margin-left: 2px !important;
}

.gri{
  background-color: @gri_deschis !important;
  color: @alb;
}
.gri-deschis{
  background-color: @alb-inchis !important;
  
  color: @alb;
}

.tabelRandMic {

  .el-table__row td{
    padding: 1px !important; 
    height: 30px !important;
  }
}

.is-focus{
  border-color: red !important;
}

.el-table__empty-block {
  span {
    color: white !important;
    font-weight: bold;
  }
  background-color: @gri_deschis !important;
}

.produse, .el-table{

  .el-table__row td{
    background-color: @alb-inchis !important;
    color: @alb;
  }
  .warning-row td{
    background: rgb(183, 44, 19) !important;
  }  
  
  .factura-neincasata-row td{
    background: rgb(183, 44, 19) !important;
  }
}
 
.el-dialog {
  background-color: @gri_inchis !important;
}

.swal2-container {
  z-index: 999999 !important;
}

.el-dialog__header {
  // background-color: red !important;
  color: @alb !important;
}

.el-dialog__wrapper{

  color: @alb !important;

}
.el-dialog__title{

  color: @alb !important;

  
}

.el-table__expand-icon {
      color: white !important;
    }



 </style>
