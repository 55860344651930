import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        { path: '/', name: 'home', component: () => import('./pages/Login.vue') },
        { path: '/tva', name: 'tva', component: () => import('./pages/tva/Tva_list.vue') },
        // { path: '/dashboard'            , name: 'dashboard'             , component: () => import( './pages/Dashboard.vue'                                      ) },
        { path: '/categorii-drepturi', name: 'categorii-drepturi', component: () => import('./pages/categorii-drepturi/Categorii-drepturi_list.vue') },
        { path: '/drepturi', name: 'drepturi', component: () => import('./pages/drepturi/Drepturi_list.vue') },
        { path: '/grupuri-utilizatori', name: 'grupuri-utilizatori', component: () => import('./pages/grupuri-utilizatori/Grupuri-utilizatori_list.vue') },
        { path: '/utilizatori', name: 'utilizatori', component: () => import('./pages/utilizatori/Utilizatori_list.vue') },
        { path: '/clienti', name: 'clienti', component: () => import('./pages/clienti/Clienti_list.vue') },
        { path: '/facturi', name: 'facturi', component: () => import('./pages/facturi/Facturi_list.vue') },
        { path: '/facturi-efactura', name: 'facturi-efactura', component: () => import('./pages/facturi-efactura/FacturiEfactura.vue') },
        { path: '/incasari', name: 'incasari', component: () => import('./pages/incasari/Incasari_list.vue') },
        { path: '/avize', name: 'avize', component: () => import('./pages/avize/Avize_list.vue') },
        { path: '/bonuri_consum', name: 'bonuri_consum', component: () => import('./pages/bonuri_consum/Bonuri_consum_list.vue') },
        { path: '/niruri', name: 'niruri', component: () => import('./pages/niruri/Niruri_list.vue') },
        { path: '/transferuri', name: 'transferuri', component: () => import('./pages/transferuri/Transferuri_list.vue') },
        { path: '/furnizori', name: 'furnizori', component: () => import('./pages/furnizori/Furnizori_list.vue') },
        { path: '/clienti-categorii', name: 'clienti_categorii', component: () => import('./pages/clienti_categorii/Clienti_categorii_list.vue') },
        { path: '/ambalaje', name: 'ambalaje', component: () => import('./pages/ambalaje/Ambalaje_list.vue') },
        // { path: '/orase'                , name: 'orase'                 , component: () => import ('./pages/orase/Orase_list.vue'                               ) },
        { path: '/luni_blocate', name: 'luni_blocate', component: () => import('./pages/luni_blocate/Luni_blocate_list.vue') },
        { path: '/delegati', name: 'delegati', component: () => import('./pages/delegati/Delegati_list.vue') },
        { path: '/registru_casa', name: 'registru_casa', component: () => import('./pages/registru_casa/Registru_casa_list.vue') },
        { path: '/gestiuni', name: 'gestiuni', component: () => import('./pages/gestiuni/Gestiuni_list.vue') },
        { path: '/transport', name: 'transport', component: () => import('./pages/transport/Transport_list.vue') },
        { path: '/agenti', name: 'agenti', component: () => import('./pages/agenti/Agenti_list.vue') },
        { path: '/um', name: 'um', component: () => import('./pages/um/Um_list.vue') },
        { path: '/produse', name: 'produse', component: () => import('./pages/produse/Produse_list.vue') },
        { path: '/produse-categorii', name: 'produse_categorii', component: () => import('./pages/produse_categorii/Produse_categorii_list.vue') },
        { path: '/serii', name: 'serii', component: () => import('./pages/serii/Serii_list.vue') },
        { path: '/raport_fisa', name: 'raport_fisa', component: () => import('./pages/rapoarte/Raport_fisa.vue') },
        { path: '/raport_neincasate', name: 'raport_neincasate', component: () => import('./pages/rapoarte/Raport_neincasate.vue') },
        // { path: '/miscari'              , name: 'miscari'               , component: () => import ('./pages/miscari/Miscari_list.vue'                           ) },
        { path: '/stoc_marfa', name: 'stoc_marfa', component: () => import('./pages/rapoarte/Stoc_marfa.vue') },
        { path: '/stoc_marfa_clienti', name: 'stoc_marfa_clienti', component: () => import('./pages/rapoarte/Stoc_marfa_clienti.vue') },
        { path: '/stoc_marfa_niruri', name: 'stoc_marfa_niruri', component: () => import('./pages/rapoarte/Stoc_marfa_niruri.vue') },

        { path: '/top_vanzari', name: 'top_vanzari', component: () => import('./pages/rapoarte/Top_vanzari.vue') },
        { path: '/balanta', name: 'balanta', component: () => import('./pages/rapoarte/Balanta.vue') },
        { path: '/inventar', name: 'inventar', component: () => import('./pages/inventar/Inventar_list.vue') },
        { path: '/conturi_bancare', name: 'conturi_bancare', component: () => import('./pages/conturi_bancare/Conturi_bancare_list.vue') },
        { path: '/setari', name: 'setari', component: () => import('./pages/Setari.vue') },
    ]
})
